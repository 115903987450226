import * as Sentry from "@sentry/react";
import { debounce } from "lodash";
import http from "../lib/axios";
import { ParticipantApplication } from "./ParticipantApplication";
import { Answer, CompoundAnswer } from "./Question";

interface ApiResponse {
  success: boolean;
  message: any;
}

interface SendEmailApplication {
  email: string;
  surveyDefinitionUrl: string;
  agree: string;
}

interface SecurityCodeApplication {
  email: string;
  agree: string;
  securityCode: string;
}

interface AdminListResponse {
  success: boolean;
  applications: ParticipantApplication[];
  lastKey: string;
}

export default class Api {
  constructor(private readonly axios = http) {}

  static serialize = (options: any): string => {
    return Object.keys(options)
      .map((key) => key + "=" + options[key])
      .join("&");
  };

  createApplicationSendEmail(application: SendEmailApplication) {
    // Experiment to handle 400 statuses as non-exceptional.
    // (See comment in lib/axios.ts)
    const validateStatus = (s: number) => (s >= 200 && s < 300) || s === 400;

    return this.axios.post(`/application/protege`, application, {
      validateStatus,
    });
  }

  createApplicationSecurityCode(application: SecurityCodeApplication) {
    const validateStatus = (s: number) => (s >= 200 && s < 300) || s === 400;

    return this.axios.post(`/application/code`, application, {
      validateStatus,
    });
  }

  async getApplicationById(applicationId: string) {
    const validateStatus = (s: number) =>
      (s >= 200 && s < 300) || s === 400 || s === 404;
    const response = await this.axios.get(`/application/${applicationId}`, {
      validateStatus,
    });
    if (response.status === 404) return null;
    return response.data;
  }

  /* Fancy stuff; needs some explanation.
   *
   * Since form element change events can happen very frequently, we want to avoid slamming the API.  Previously, we
   * just had a simple debounce in AbstractQuestion to avoid sending a request for every keypress.  However, browser
   * autofill created another, worse, issue.  An autofilled address can trigger changes in a set of > 5 fields basically
   * simultaneously.
   *
   * Enter `patchAnswersDebounced()`, which maintains a batch of updated in `batchedAnswers`, and then invokes the
   * private `debouncedPatchAnswers` (which is just a debounced version of `patchAnswers`), patching all the updated
   * answers that have been accumulated in `batchedAnswers`.
   *
   * A possible future alternative would be to push the API interaction up to the Pane or Survey level, and implement
   * change-tracking across all answers.  Then we could just have a non-batching, straight-forward debounced
   * `patchAnswers`, since we wouldn't have to accumulate changes and clear them after a patch.
   */
  private batchedAnswers = new Map<string, Answer>();

  private debouncedPatchAnswers = debounce(
    async (uuid: string, answers: CompoundAnswer) => {
      /* This implementation swallows errors intentionally.  We assume any failures are likely to be transient, and all
         responses will be persisted when the user clicks next/previous/submit.  Errors that result on those actions
         are reported more loudly.
       */
      try {
        // send all batched changes.
        const result = await this.axios.patch(
          `/application/${uuid}/answers`,
          { answers },
          // ignore server-side errors here.  hopefully they're transient.  everything will get saved on next/submit.
          { validateStatus: () => true },
        );
        // if server indicates success, clear batch.
        if (result.status === 200 && result.data.success === true) {
          this.batchedAnswers.clear();
        }
        return result;
      } catch (e) {
        // Swallow any errors, but log them to Sentry.  Return a mocked-up 503 response.
        Sentry.captureException(e);
        const message = e instanceof Error ? e.message : "Unexpected error";
        return {
          status: 503,
          data: { success: false, message },
        };
      }
    },
    750,
    { maxWait: 7500 },
  );

  patchAnswersDebounced(uuid: string, answers: CompoundAnswer) {
    answers.forEach(([q, a]) => {
      this.batchedAnswers.set(q, a);
    });
    return this.debouncedPatchAnswers(
      uuid,
      Array.from(this.batchedAnswers.entries()),
    );
  }

  /* END Fancy Stuff; We now return to your regularly scheduled programming */

  patchAnswers(uuid: string, answers: CompoundAnswer) {
    return this.axios.patch(`/application/${uuid}/answers`, { answers });
  }

  submitApplication(application: ParticipantApplication) {
    const validateStatus = (s: number) => (s >= 200 && s < 300) || s === 400;

    return this.axios.put(
      `/application/${application.applicationId}/submit`,
      application,
      { validateStatus },
    );
  }

  async createSignedUploadUrl(
    appId: string,
    filename: string,
    type: string,
  ): Promise<string> {
    const params = { filename, type };
    const res = await this.axios.get(`/a/${appId}/photo-url`, { params });
    return res.data.uploadUrl;
  }

  async getApplications(lastKey?: string): Promise<AdminListResponse> {
    const url = `/admin/applications`;
    const config = {
      headers: { Authorization: localStorage.getItem("credentials") },
      params: { more: lastKey },
    };
    const res = await this.axios.get(url, config);
    return res.data;
  }

  async authenticate(credentials: {
    username: string;
    password: string;
  }): Promise<ApiResponse> {
    const validateStatus = (s: number) => (s >= 200 && s < 300) || s === 400;
    const res = await this.axios.post(`/auth`, credentials, {
      validateStatus,
    });
    return res.data;
  }

  async getApplicationLogs(query: { appId: string; from: number }): Promise<{
    results: any[];
    success: boolean;
    status: number;
    email: string;
  }> {
    let queryString = "";
    if (query) {
      queryString = `?${Api.serialize(query)}`;
    }
    return (await this.axios.get(`/logs${queryString}`)).data;
  }
}
