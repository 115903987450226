import {
  CompoundQuestionDefinition,
  QuestionDefinition,
  QuestionVariety,
} from "./Definition";
import { createQuestion } from "./Factory";
import {
  Answer,
  CompoundAnswer,
  Question,
  QuestionContext,
  visibilityFunc,
} from "./Question";

export class CompoundQuestion extends Question implements QuestionContext {
  protected subQuestions: Question[] = [];

  constructor(
    protected readonly def: QuestionDefinition,
    isVisible?: visibilityFunc,
  ) {
    super(def, isVisible, () => ({ isValid: true, errorText: "" }));
    if (!CompoundQuestion.isCompoundDefinition(def)) {
      throw new Error(
        `Invalid CompoundQuestionDefinition for ${def.name}: no 'questions' property present`,
      );
    } else {
      def.questions.forEach((qd) => {
        this.addQuestion(qd);
      });
    }
  }

  /*
   * Compound questions are valid when they have no invalid children
   */
  isValid() {
    const invalidQuestions = this.questions.filter((q) => !q.isValid());
    return invalidQuestions.length === 0;
  }

  /*
   * getQuestion() and hasQuestionNamed() implement QuestionContext
   *
   * Note that these method implementations are identical to those in Pane.
   * @todo Consider using a "mixin" to DRY this up.  See:
   *     https://www.typescriptlang.org/docs/handbook/mixins.html
   */
  getQuestion(name: string) {
    const question = this.subQuestions.find((q) => q.name === name);
    if (question) return question;
    else throw new Error(`No question named '${name}'`);
  }

  hasQuestionNamed(name: string) {
    return !!this.subQuestions.find((q) => q.name === name);
  }

  private addQuestion(def: QuestionDefinition) {
    if (this.hasQuestionNamed(def.name)) {
      throw new Error(`Duplicate question name: ${def.name}`);
    }

    this.subQuestions.push(createQuestion(def, this));
  }

  get type(): QuestionVariety {
    return "compound";
  }

  get questions(): Question[] {
    return this.subQuestions;
  }

  setSubAnswer(questionName: string, answer: Answer): void {
    try {
      this.getQuestion(questionName).answer = answer;
    } catch (e) {
      console.warn(e);
    }
  }

  get isEmpty() {
    return this.subQuestions.filter((q) => !q.isEmpty).length === 0;
  }

  get answer(): CompoundAnswer {
    const answer = this.subQuestions.map<[string, Answer]>((q) => [
      q.name,
      q.answer,
    ]);

    return answer;

    // return new Map(
    //   this.subQuestions.map<[string, Answer]>(q => [q.name, q.answer])
    //
    //   /*
    //     So, ideally this should work, but doesn't:
    //
    //       this.subQuestions.map( q => [q.name, q.answer] )
    //
    //     because TypeScript thinks that returns Answer[][] for some reason.
    //
    //     This SO answer seems to explain why:
    //
    //
    //     Another thing that works, if you prefer:
    //
    //       this.subQuestions.map( q => [q.name, q.answer] ) as Array<[string, Answer]>
    //
    //       or
    //
    //       this.subQuestions.map((q): [string, Answer] => [q.name , q.answer])
    //     */
    // );
  }

  set answer(a: CompoundAnswer) {
    a.map(([q, ans]) => this.setSubAnswer(q, ans));
    // a.forEach((answer, questionName) => {
    //   this.setSubAnswer(questionName, answer);
    // });
  }

  /* This is a "type guard".
   *
   * It's return type is a "type predicate", and the TypeScript compiler can use it narrow the type.  See the
   * if-statement in the constructor or how this is useful.  Without the type guard, the "else" block would be
   * a compile error.
   *
   * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
   */
  private static isCompoundDefinition(
    def: QuestionDefinition,
  ): def is CompoundQuestionDefinition {
    return (def as CompoundQuestionDefinition).questions !== undefined;
  }

  public static isCompoundQuestion(q: Question): q is CompoundQuestion {
    return q.type === "compound";
  }
}
