import { Col, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import HelpBox from "../components/HelpBox";

import logo from "../logo.png";

const Success = () => (
  <div>
    <Row
      style={{
        marginBottom: "2em",
        paddingBottom: "1em",
        background: "#fff",
      }}
    >
      <Col xs={12}>
        <h3 className="pull-left">Check your email</h3>
        <img
          className="pull-right"
          src={logo}
          style={{ margin: "0.5em" }}
          alt="ACP - American Corporate Partners"
        />
      </Col>
      <Col xs={12}>
        <p>
          To continue to your application, please check your email and click the
          link provided. Check your spam as our messages are sometimes filtered
          or blocked.
        </p>
      </Col>
    </Row>
    <div style={{ textAlign: "center", margin: "10px 0" }}>
      <HelpBox />
      <Footer />
    </div>
  </div>
);

export default Success;
