import * as React from "react";
import { Redirect } from "react-router";
import Api from "../lib/api";

const api = new Api();

export const Protected = (
  C: React.ComponentType<any>,
): React.ComponentType<any> => {
  return class App extends React.Component<any> {
    state = {
      loading: true,
      authorized: true,
    };
    async componentDidMount() {
      const session = localStorage.getItem("credentials")
        ? JSON.parse(localStorage.getItem("credentials")!)
        : null;

      const timeNow = new Date().getTime();
      if (session && session.expiry > timeNow) {
        const response = await api.authenticate({
          username: session.username,
          password: session.password,
        });
        if (response.success) {
          this.setState({
            loading: false,
            authorized: true,
          });
        } else {
          console.log("Authentication failed.");
          this.setState({
            loading: false,
            authorized: false,
          });
        }
      } else {
        console.log("Session Expired.  Clearing credentials");
        localStorage.removeItem("credentials");
        this.setState({
          loading: false,
          authorized: false,
        });
      }
    }
    render() {
      if (this.state.loading) return <div>Authorizing</div>;
      if (this.state.authorized) return <C {...this.props} />;
      return <Redirect to="/login" />;
    }
  };
};
