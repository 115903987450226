import Cookies from "universal-cookie";
import CheckboxesQuestion from "./CheckboxesQuestion";
import { CompoundQuestion } from "./CompoundQuestion";
import { QuestionDefinition } from "./Definition";
import MultilineTextQuestion from "./MultilineTextQuestion";
import PhotoUploadQuestion from "./PhotoUploadQuestion";
import {
  QuestionConstructor,
  QuestionContext,
  regexValidator,
  SingleValueAnswer,
  visibilityFunc,
} from "./Question";
import RadiosQuestion from "./RadiosQuestion";
import RankedNSelectsQuestion from "./RankedNSelectsQuestion";
import SelectSingleQuestion from "./SelectSingleQuestion";
import SelectYearQuestion from "./SelectYearQuestion";
import SimpleTextQuestion from "./SimpleTextQuestion";
import ToDoQuestion from "./ToDoQuestion";

const qTypes: { [s: string]: QuestionConstructor } = {
  text_simple: SimpleTextQuestion,
  checkboxes: CheckboxesQuestion,
  radios: RadiosQuestion,
  text_multi: MultilineTextQuestion,
  select_single: SelectSingleQuestion,
  compound: CompoundQuestion,
  ranked_n_selects: RankedNSelectsQuestion,
  photo_upload: PhotoUploadQuestion,
  select_year: SelectYearQuestion,
  todo: ToDoQuestion,
};

export function createQuestion(
  def: QuestionDefinition,
  context?: QuestionContext,
) {
  let validator;
  let visibility: visibilityFunc | undefined;

  // throw if we don't know how to build this type of question.
  if (typeof qTypes[def.type] !== "function") {
    console.error(def, qTypes);
    throw new Error(`Unknown question variety: '${def.type}'`);
  }

  // handle prereq/visibility by setting `visibility` to a visbilityFunc function
  if (def.prereq) {
    if (context === undefined) {
      throw new Error(
        `createQuestion() cannot build "${def.name}" without context, because there is a prereq`,
      );
    }

    // not strictly necessary as getQuestion will throw, but this provides a nicer error message.
    if (!context.hasQuestionNamed(def.prereq.input)) {
      throw new Error(
        `${def.name} has invalid prereq: no previous question named ${def.prereq.input}`,
      );
    }

    const prereqQuestion = context.getQuestion(def.prereq.input);

    let prereqValue = def.prereq.value;

    // magic values or prereq.value:
    if (prereqValue === "__current_year") {
      prereqValue = new Date().getFullYear().toString();
    }

    if (typeof prereqQuestion.answer === "string") {
      // single-value questions
      visibility = (): boolean => prereqQuestion.answer === prereqValue;
    } else {
      // multi-choice questions (checkboxes, etc).
      visibility = (): boolean => {
        const q = prereqQuestion.answer as SingleValueAnswer;
        return q.includes(prereqValue);
      };
    }
  }

  // build a validator
  if (def.validate && def.validate.regex) {
    validator = regexValidator(
      new RegExp(def.validate.regex),
      def.validate.message,
    );
  }

  const question = new qTypes[def.type](def, visibility, validator);

  // special handling for referral source when they user has a cookie
  const cookies = new Cookies();
  const referrer = cookies.get("referred-by");
  if (referrer && question.target === "referralSource") {
    if (CompoundQuestion.isCompoundQuestion(question)) {
      question.answer = [["Referral.Main", referrer]];
    } else {
      question.answer = referrer;
    }
  }

  return question;
}
