import * as React from "react";
import { FormControl } from "react-bootstrap";
import { AbstractQuestion } from "./AbstractQuestion";
import { MultiValueQuestionProps } from "./QuestionProps";
import QuestionRow from "./QuestionRow";

interface RankedNSelectsQuestionState {
  answers: string[];
}

export class RankedNSelectsQuestion extends AbstractQuestion<
  MultiValueQuestionProps,
  RankedNSelectsQuestionState
> {
  constructor(props: MultiValueQuestionProps) {
    super(props);
    this.state = {
      answers: props.question.answer,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  setNewAnswers(evt: any) {
    const answerValue = evt.target.value;
    const newAnswers = this.state.answers;
    newAnswers[evt.target.id] = answerValue;
    this.setState({
      answers: newAnswers,
    });

    return newAnswers.filter((v) => v);
  }

  handleChange(evt: React.FormEvent<FormControl>) {
    const flatArray = this.setNewAnswers(evt);
    this.onChange({ target: { value: flatArray } });
  }

  handleBlur(evt: React.FormEvent<FormControl>) {
    const flatArray = this.setNewAnswers(evt);
    this.onBlur({ target: { value: flatArray } });
  }

  generateOptions = () => {
    const q = this.props.question;
    return Array.from(q.options.entries()).map(([val, lbl]) => {
      return (
        <option
          value={val}
          key={val}
          hidden={this.state.answers.indexOf(val) !== -1}
        >
          {lbl}
        </option>
      );
    });
  };
  render() {
    const q = this.props.question;
    return (
      <QuestionRow
        label={q.label}
        required={q.isRequired}
        errorText={q.errorText}
        visible={q.isVisible()}
        helpText={q.helpText}
        validationState={
          q.isValid() || !this.showValidationState ? null : "error"
        }
      >
        {
          /*  Loop through maxAnswers and fill so that .map won't skip */
          Array(q.maxAnswers)
            .fill(1)
            .map((_, i) => (
              <FormControl
                componentClass="select"
                key={i}
                id={i.toString()}
                defaultValue={q.answer[i]}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              >
                <option value="" />
                {this.generateOptions()}
              </FormControl>
            ))
        }

        <FormControl.Feedback />
      </QuestionRow>
    );
  }
}
