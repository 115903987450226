import { FormControl } from "react-bootstrap";
import { AbstractQuestion } from "./AbstractQuestion";
import { QuestionProps } from "./QuestionProps";
import QuestionRow from "./QuestionRow";

export class SimpleTextQuestion extends AbstractQuestion<QuestionProps> {
  render() {
    const q = this.props.question;
    // console.log("Rendering SimpleTextQuestion: " + q.label);
    return (
      <QuestionRow
        label={q.label}
        required={q.isRequired}
        errorText={q.errorText}
        visible={q.isVisible()}
        helpText={q.helpText}
        validationState={
          q.isValid() || !this.showValidationState ? null : "error"
        }
      >
        <FormControl
          type="text"
          name={q.name}
          defaultValue={q.answer as string}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder={q.placeholder}
          autoComplete={q.autoComplete}
        />
        <FormControl.Feedback />
      </QuestionRow>
    );
  }
}
