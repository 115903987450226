// import { debounce } from "lodash";
import * as React from "react";
import Api from "../lib/api";
import { Answer, Question } from "../lib/Question";
import { CompoundQuestionProps, QuestionProps } from "./QuestionProps";
import { ComponentState } from "react";

const api = new Api();

// flat map of values that should trigger a re-render if they are changed.
// see shouldComponentUpdate below
interface FlatQuestion {
  name: string;
  label: string;
  required: boolean;
  visible: boolean;
  valid: boolean;
  answer: string;
  isDirty: boolean;
}

const jsonEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);

export type QuestionConstructor = new (
  props: QuestionProps,
) => AbstractQuestion<QuestionProps, object>;
export type CompoundQuestionConstructor = new (
  props: CompoundQuestionProps,
) => AbstractQuestion<CompoundQuestionProps, object>;

export abstract class AbstractQuestion<
  P extends QuestionProps,
  S extends ComponentState = object,
> extends React.Component<P, S> {
  // used to decide shouldComponentUpdate
  protected flatQuestion: FlatQuestion | null = null;

  // pseudo-state for tracking whether the user has interacted with this control.
  private isDirty: boolean = false;

  handlePatch = (q: string, a: Answer) => {
    try {
      api.patchAnswersDebounced(this.props.applicationId, [[q, a]]);
    } catch (e) {
      // this is sneaky, very cool, and very legal:
      //
      this.setState(() => {
        throw new Error("OMG NO");
      });
    }
  };

  // handlePatch = debounce((q: string, a: Answer) => {
  //   api.patchAnswers(this.props.applicationId, [[q, a]]);
  // }, 500);

  onChange = (e: any) => {
    if (!this.props.isSubQuestion) {
      this.handlePatch(this.props.question.name, e.target.value);
    }
    this.props.onChange(this.props.question.name, e.target.value);
  };

  onBlur = (e: any) => {
    this.isDirty = true;
    if (this.props.onBlur) {
      this.props.onBlur(this.props.question.name, e.target.value);
      return;
    }
  };

  // If true, component should show error indicator+messages if field is invalid.
  // If false, the field is still "pristine"
  get showValidationState() {
    return (
      this.isDirty || this.props.dirty // user touched this // user clicked Save/Submit
    );
  }

  shouldComponentUpdate(
    nextProps: Readonly<QuestionProps>,
    nextState: Readonly<ComponentState>,
    // nextContext: any
  ): boolean {
    const nextShowValidationState = this.isDirty || nextProps.dirty;
    return (
      !jsonEqual(
        this.flatten(nextProps.question, nextShowValidationState),
        this.flatQuestion,
      ) || !jsonEqual(this.state, nextState)
    );
  }

  componentDidUpdate() {
    this.flatQuestion = this.flatten(
      this.props.question,
      this.showValidationState,
    );
  }

  protected flatten = (q: Question, isDirty: boolean): FlatQuestion => {
    let ans = q.answer;

    // @todo - q.answer should never be a map?  remove this?
    if (q.answer instanceof Map) {
      const newAns: Array<[string, Answer]> = [];
      q.answer.forEach((val, key) => {
        newAns.push([key, val]);
      });

      ans = newAns.toString();
    }

    return {
      name: q.name,
      label: q.label,
      required: q.isRequired,
      visible: q.isVisible(),
      valid: q.isAnswerValid(),
      answer: [ans].join("|"), // this should handle string | string[] just fine.
      isDirty,
    };
  };
}
//
