import { AbstractQuestion } from "./AbstractQuestion";
import { SingleValueQuestionProps } from "./QuestionProps";
import QuestionRow from "./QuestionRow";

export class ToDoQuestion extends AbstractQuestion<SingleValueQuestionProps> {
  render() {
    const q = this.props.question;

    return (
      <QuestionRow
        label={q.label}
        required={q.isRequired}
        errorText={q.errorText}
        visible={q.isVisible()}
        helpText={q.helpText}
        validationState={
          q.isValid() || !this.showValidationState ? null : "error"
        }
      >
        {q.answer}
      </QuestionRow>
    );
  }
}
