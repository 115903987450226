import { QuestionVariety } from "./Definition";
import { MultiValueQuestion } from "./MultiValueQuestion";

export default class RankedNSelectsQuestion extends MultiValueQuestion {
  get type(): QuestionVariety {
    return "ranked_n_selects";
  }

  /**
   * Overrides default sorting to preserve user's ranking of choices.
   */
  protected sortAnswers(answers: string[]): string[] {
    return answers;
  }
}
