import { FormControl, Radio } from "react-bootstrap";
import { AbstractQuestion } from "./AbstractQuestion";
import { SingleValueQuestionProps } from "./QuestionProps";
import QuestionRow from "./QuestionRow";

export class RadiosQuestion extends AbstractQuestion<SingleValueQuestionProps> {
  render() {
    const q = this.props.question;

    return (
      <QuestionRow
        label={q.label}
        required={q.isRequired}
        errorText={q.errorText}
        visible={q.isVisible()}
        helpText={q.helpText}
        validationState={
          q.isValid() || !this.showValidationState ? null : "error"
        }
      >
        {Array.from(q.options.entries()).map(([val, lbl]) => (
          <Radio
            key={val}
            name={q.name}
            value={val}
            onChange={this.onChange}
            checked={q.answer === val}
          >
            {lbl}
          </Radio>
        ))}
        <FormControl.Feedback />
      </QuestionRow>
    );
  }
}
