import * as Sentry from "@sentry/react";
import axios, { AxiosRequestConfig } from "axios";
import { Button, FormControl, Glyphicon } from "react-bootstrap";
import Api from "../lib/api";
import { AbstractQuestion } from "./AbstractQuestion";
import { SingleValueQuestionProps } from "./QuestionProps";
import QuestionRow from "./QuestionRow";

import loadImage from "blueimp-load-image";

interface State {
  uploading: boolean;
  errorText: string;
  validationState: "error" | null;
  base64image: string | null;
  // imgLoading: boolean;
}

const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

export class PhotoUploadQuestion extends AbstractQuestion<
  SingleValueQuestionProps,
  State
> {
  private fileInput: HTMLInputElement | null = null;

  private readonly api: Api;

  constructor(props: SingleValueQuestionProps) {
    super(props);
    this.state = {
      uploading: false,
      errorText: "",
      validationState: null,
      base64image: null,
      // imgLoading: true
    };
    this.api = new Api();
  }

  imageCanvas: null | HTMLDivElement = null;

  render() {
    const q = this.props.question;
    // console.log("Rendering PhotoUploadQuestion: " + q.label);
    if (q.answer !== "") {
      loadImage(
        q.answer,

        (img: any) => {
          if (this.imageCanvas) {
            this.imageCanvas.innerHTML = "";
            img.style.maxWidth = "75%";
            img.style.height = "auto";
            img.style.margin = "20px auto 10px auto";
            img.style.display = "block";
            this.imageCanvas.appendChild(img);
          }
        },
        { orientation: true, meta: true },
      );
    } else {
      if (this.imageCanvas) this.imageCanvas.innerHTML = "";
    }

    return (
      <QuestionRow
        label={q.label}
        required={q.isRequired}
        errorText={this.state.errorText}
        visible={q.isVisible()}
        helpText={q.helpText}
        validationState={this.state.errorText === "" ? null : "error"}
      >
        <FormControl
          type="file"
          name={q.name}
          // defaultValue={q.answer}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          style={{ display: "none" }}
          inputRef={(ref) => {
            this.fileInput = ref;
          }}
          accept={validTypes.join(",")}
          // placeholder={q.placeholder}
        />

        {/* This should probably become its own component? */}
        <Button
          block={true}
          bsStyle={"default"}
          onClick={this.onUploadClick}
          disabled={this.state.uploading}
        >
          <Glyphicon
            glyph={this.state.uploading ? "refresh" : "camera"}
            className={this.state.uploading ? "spin" : undefined}
          />
          &nbsp;
          {this.state.uploading ? "Uploading ..." : "Upload Photo"}
        </Button>

        <div ref={(ref) => (this.imageCanvas = ref)} />

        {q.answer && (
          <Button onClick={this.onClearClick} className="btn-sm center-block">
            <Glyphicon glyph="remove" /> Remove Photo
          </Button>
        )}
        <FormControl.Feedback />
      </QuestionRow>
    );
  }

  onUploadClick = () => {
    if (this.fileInput) {
      this.fileInput.click();
    }
  };

  onClearClick = () => {
    if (this.fileInput) {
      this.fileInput.value = "";
    }
    this.onChange({ target: { value: "" } });
  };

  handleChange = async (e: any) => {
    const f = e.target.files[0];

    const maxBytes = 1024 * 1024 * 21; // 22020096;

    if (!f) {
      this.setState({
        errorText: `Invalid file.`,
        validationState: "error",
      });
      return;
    }

    if (!validTypes.includes(f.type)) {
      this.setState({
        errorText: `Invalid file type '${f.type}'.  Image must be .jpg, .png, or .webp`,
        validationState: "error",
      });
      return;
    }

    if (f.size > maxBytes) {
      // console.error("File too large.  Max size: 20MB");
      this.setState({
        errorText: `File too large.  Maximum size: 20MB`,
        validationState: "error",
      });
      return;
    }

    this.setState({
      uploading: true,
      errorText: "",
      validationState: null,
    });

    try {
      const signedUrl = await this.api.createSignedUploadUrl(
        this.props.applicationId,
        f.name,
        f.type,
      );

      // push file to s3
      const putOpts = {
        headers: { "Content-Type": f.type },
        responseType: "blob",
      } as AxiosRequestConfig;
      await axios.put(signedUrl, f, putOpts);

      // strip query string
      const getUrl = signedUrl.split("?")[0];

      // prefetch the image
      await axios.get(getUrl);

      this.setState({ uploading: false });
      this.onChange({ target: { value: getUrl } });
    } catch (e) {
      // @todo - validation seems to display nothing here.  Try turning off network and uploading file.  Fails silently.
      console.error("Upload failed: ", e);
      this.setState({
        uploading: false,
        errorText: "Something went wrong.  Please try again.",
        validationState: "error",
      });
      Sentry.captureException(e);
    }
  };
}
