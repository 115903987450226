/*
 * Interfaces and functions for working with survey definitions and their components
 */
import * as t from "io-ts";
import { ThrowReporter } from "io-ts/lib/ThrowReporter";
/*
 * Some notes about io-ts.
 *
 * Since we're consuming json definitions, it would be nice if we had typescript types/interfaces at runtime so we
 * could use them to validate the definition json.  Enter io-ts, which allows us to construct runtime types, and then
 * create the equivalent static types for use at compile time.  It's a two-step process (see PrereqConstraint[Type]
 * below for an annotated example.
 *
 * The runtime types are essentially validators, which in our context is great, as we can use them to validate the
 * structure of Survey Definition and its various components.
 *
 * @see https://github.com/gcanti/io-ts
 * @see https://www.olioapps.com/blog/checking-types-real-world-typescript/
 * @see https://lorefnon.tech/2018/03/25/typescript-and-validations-at-runtime-boundaries/
 */

/*
 * io-ts les us refine a type by applying a predicate.  Here's one that makes sure some string is a valid regular
 * expression.
 */
const RegExpType = t.refinement(
  t.string,
  (s) => {
    try {
      // tslint:disable-next-line:no-unused-expression
      new RegExp(s);
    } catch (err) {
      return false;
    }
    return true;
  },
  "RegularExpression",
);

/*
We want this, but extended into runtime:

type PrereqConstraint {
  input: string;
  value: string;
}

So we do this ...
 */

// we create a runtime type using io-ts
const PrereqConstraintType = t.type(
  {
    input: t.string,
    value: t.string,
  },
  "PrereqConstraint",
);
// then we could create a static type by using the TypeOf operator.
// type PrereqConstraint = t.TypeOf<typeof PrereqConstraintType>;

/*
Same as above, but this time we want in `interface` instead of a `type`.  Here's how we would define the
interface in vanilla typescript:

interface ValidateConstraint {
  regex: string,
  message: string
}
*/

const ValidateConstraintType = t.type(
  {
    regex: RegExpType,
    message: t.string,
  },
  "ValidateConstraint",
);
// Now, instead of defining a `type` as above, we can define an `interface` like so:
// interface ValidateConstraint extends t.TypeOf<typeof ValidateConstraintType> {}

/*
We can build an enum-like type by using t.keyof
@see https://github.com/gcanti/io-ts/tree/1dbcd2b5730255f384591fc0c7d0bbfba52697d3#union-of-string-literals
*/
const QuestionVarietyType = t.keyof(
  {
    text_simple: null,
    // text_hidden: null,
    text_multi: null,
    select_single: null,
    checkboxes: null,
    radios: null,
    compound: null,
    ranked_n_selects: null,
    photo_upload: null,
    select_year: null,
    // used during development for question-types that haven't been implemented.  remove later.
    todo: null,

    // used in tests only.
    _abstract: null,
  },
  "QuestionVariety",
);

export type QuestionVariety = t.TypeOf<typeof QuestionVarietyType>;

/*
A type that describes a flat object with string properties and values.
 */
const StringOptionsType = t.dictionary(t.string, t.string, "StringOptions");
export interface StringOptions extends t.TypeOf<typeof StringOptionsType> {}

/*

Things get a little more complicated when the type/interface we want to model has optional properties.  For our
question-definitions, we want something equivalent to:

export interface QuestionDefinition {
  name: string;
  label: string;
  type: string;
  required: boolean;
  target: string;
  position?: number;
  prereq?: PrereqConstraint
  validate?: ValidateConstraint
}

io-ts provides the partial() combinator, which creates a type where *all* properties are optional.  We can combine
such a type with a regular type from type(), using the intersection() combinator to get what we want.

@see https://github.com/gcanti/io-ts/blob/1.3.0/README.md#mixing-required-and-optional-props

*/

// const SimpleQuestionDefinitionType = t.intersection([
//   t.type({
//     name: t.string,
//     label: t.string,
//     type: QuestionVarietyType,
//     target: t.string,
//   }),
//   t.partial({
//     position: t.number,
//     required: t.boolean,
//     prereq: PrereqConstraintType,
//     validate: ValidateConstraintType,
//     helpText: t.string,
//     options: StringOptionsType,
//     optionsUrl: t.string,
//     placeholder: t.string,
//     maxAnswers: t.number
//   })
// ], "SimpleQuestionDefinition");
// export type SimpleQuestionDefinition = t.TypeOf<typeof SimpleQuestionDefinitionType>

// const CompoundQuestionDefinitionType = t.intersection([
//   t.type({
//     name: t.string,
//     type: t.literal("compound"),
//     questions: t.array(SimpleQuestionDefinitionType),
//     target: t.string
//   }),
//   t.partial({
//     position: t.number,
//     require: t.boolean,
//     prereq: PrereqConstraintType,
//     validate: ValidateConstraintType,
//     helpText: t.string
//   })
// ], "CompoundQuestionDefinition");
// export type CompoundQuestionDefinition = t.TypeOf<typeof CompoundQuestionDefinitionType>

// const QuestionDefinitionType = t.union([SimpleQuestionDefinitionType, CompoundQuestionDefinitionType], "QuestionDefinition");

// export interface QuestionDefinition extends t.TypeOf<typeof QuestionDefinitionType> {}
// export type QuestionDefinition = t.TypeOf<typeof QuestionDefinitionType>;

// All Questions have these properties.
const BaseQuestionDefType = t.intersection([
  t.type({
    name: t.string,
    target: t.string,
  }),
  t.partial({
    position: t.number,
  }),
]);

// const HiddenQuestionDefType = t.exact(
//     BaseQuestionDefType,
//     "HiddenQuestionDef"
// );

// Non-Compound questions all share these properties.
const SimpleQuestionDefType = t.intersection(
  [
    BaseQuestionDefType,
    t.type({
      label: t.string,
    }),
    t.partial({
      required: t.boolean,
      prereq: PrereqConstraintType,
      validate: ValidateConstraintType,
      helpText: t.string,
    }),
  ],
  "SimpleQuestionDef",
);

// Used as the tag in tagged union.
const OptionsDefinitionType = t.union(
  [t.type({ options: StringOptionsType }), t.type({ optionsUrl: t.string })],
  "OptionsDefinition",
);

// This is just a helper function, used immediately below.
const mkQuestionType = (type: string, label: string, extra?: t.Type<any>) => {
  // sort of over simple here, but types get weird and confusing
  if (extra) {
    return t.intersection(
      [SimpleQuestionDefType, t.type({ type: t.literal(type) }), extra],
      label,
    );
  } else {
    return t.intersection(
      [SimpleQuestionDefType, t.type({ type: t.literal(type) })],
      label,
    );
  }
};

const SimpleTextQuestionType = mkQuestionType(
  "text_simple",
  "SimpleTextQuestion",
);

// const HiddenQuestionType = t.intersection(
//     [HiddenQuestionDefType, t.type({ type: t.literal("text_hidden") })],
// )

const MultilineTextQuestionType = mkQuestionType(
  "text_multi",
  "MultilineTextQuestion",
);
const RadiosQuestionType = mkQuestionType(
  "radios",
  "RadiosQuestion",
  OptionsDefinitionType,
);
const CheckboxesQuestionType = mkQuestionType(
  "checkboxes",
  "CheckboxesQuestion",
  OptionsDefinitionType,
);
const SelectSingleQuestionType = mkQuestionType(
  "select_single",
  "SelectSingleQuestion",
  OptionsDefinitionType,
);

const SelectYearQuestionType = mkQuestionType(
  "select_year",
  "SelectYearQuestion",
);
const RankedNSelectsQuestionType = mkQuestionType(
  "ranked_n_selects",
  "RankedNSelectsQuestion",
  OptionsDefinitionType,
);

const PhotoUploadQuestionType = mkQuestionType(
  "photo_upload",
  "PhotoUploadQuestion",
);

// Only used in test
const AbstractQuestionType = mkQuestionType("_abstract", "AbstractQuestion");

// Only used during development.  Will be removed.
const ToDoQuestionType = mkQuestionType("todo", "ToDoQuestion");

// This and QuestionDefinitionType below ought to be able to be DRYed up using
// io-ts "recursion" operator, but that's a bit too confusing for now.
const SimpleQuestionDefinitionType = t.taggedUnion(
  "type",
  [
    MultilineTextQuestionType,
    SimpleTextQuestionType,
    // HiddenQuestionType,
    RadiosQuestionType,
    CheckboxesQuestionType,
    SelectSingleQuestionType,
    SelectYearQuestionType,
    RankedNSelectsQuestionType,
    PhotoUploadQuestionType,

    AbstractQuestionType,
    ToDoQuestionType,
  ],
  "SimpleQuestionDefinition",
);

const CompoundQuestionDefinitionType = t.intersection(
  [
    BaseQuestionDefType,
    t.type({
      type: t.literal("compound"),
      questions: t.array(SimpleQuestionDefinitionType),
    }),
  ],
  "CompoundQuestionDefinition",
);
export type CompoundQuestionDefinition = t.TypeOf<
  typeof CompoundQuestionDefinitionType
>;

const QuestionDefinitionType = t.taggedUnion(
  "type",
  [
    MultilineTextQuestionType,
    SimpleTextQuestionType,
    // HiddenQuestionType,
    RadiosQuestionType,
    CheckboxesQuestionType,
    SelectSingleQuestionType,
    SelectYearQuestionType,
    RankedNSelectsQuestionType,
    CompoundQuestionDefinitionType,
    PhotoUploadQuestionType,

    AbstractQuestionType,
    ToDoQuestionType,
  ],
  "QuestionDefinitionType",
);

export type QuestionDefinition = t.TypeOf<typeof QuestionDefinitionType>;

/**
 * Pane and Survey definitions
 */

const PaneDefinitionType = t.type(
  {
    title: t.string,
    questions: t.array(QuestionDefinitionType),
  },
  "PaneDefinition",
);

const SurveyDefinitionType = t.type(
  {
    title: t.string,
    welcomeText: t.string,
    agreeText: t.string,
    panes: t.array(PaneDefinitionType),
  },
  "SurveyDefinition",
);
export interface SurveyDefinition
  extends t.TypeOf<typeof SurveyDefinitionType> {}

/*
 * Validates a survey definition, returning it on success.  Throws on error.
 */
export function validateSurveyDefinition(def: any): SurveyDefinition {
  const res = SurveyDefinitionType.decode(def);
  ThrowReporter.report(res);
  // we know the type, even though TS doesn't, since ThrowReporter can't refine it.
  return res.value as SurveyDefinition;
}
