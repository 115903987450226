import { QuestionDefinition } from "./Definition";
import { createQuestion } from "./Factory";
import { Question, QuestionContext } from "./Question";
import { Survey } from "./Survey";

/**
 * A Pane composes a list of questions (implemented with a Map<string, Question>) and a title, and provides some
 * methods to operate on the questions.  The `context` constructor arg can be used to make the Pane aware of the
 * Survey to which it belongs.  When `context` is omitted, the Pane's context is itself.
 */
export class Pane implements QuestionContext {
  readonly questions: Question[];
  context: QuestionContext;

  constructor(
    definitions: QuestionDefinition[],
    readonly title: string,
    context?: QuestionContext,
  ) {
    this.context = context || this;
    this.questions = [];
    definitions.forEach((def: QuestionDefinition) => {
      this.addQuestion(def);
    });
  }

  /**
   * Adds a question to this pane, ensuring its name is unique in the active context.
   */
  private addQuestion(def: QuestionDefinition) {
    if (this.context.hasQuestionNamed(def.name)) {
      throw new Error(`Duplicate question name: ${def.name}`);
    }
    this.questions.push(createQuestion(def, this.context));
  }

  hasQuestionNamed(name: string) {
    return !!this.questions.find((q) => q.name === name);
  }

  getQuestion(name: string) {
    const question = this.questions.find((q) => q.name === name);
    if (question) return question;
    else throw new Error(`No question named '${name}'`);
  }

  // Maybe it's nice to show "Page N/M" stuff somewhere?
  get ordinal() {
    if (this.context instanceof Survey) {
      return this.context.panes.indexOf(this) + 1;
    }
    return 1;
  }

  isValid() {
    // this.questions.map(q => console.log(q.name));
    return this.questions.filter((q) => !q.isValid()).length === 0;
  }

  questionRefs: Array<HTMLDivElement | null> = [];

  scrollToError() {
    for (let i = 0; i < this.questions.length; i++) {
      const q = this.questions[i];
      const ref = this.questionRefs[i];
      if (!q.isValid() && ref) {
        // "smooth" seems to work kay in FF if we delay animation a little.
        setTimeout(() => {
          ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 200);

        // const pos = ref.offsetTop - 20;

        // This works fine in FF, but is abrupt.
        // window.scrollTo(0, pos);

        // this would be ideal, but firefox is unreliable.
        // window.scrollTo({
        //   top: pos,
        //   left: 0,
        //   behavior: "smooth"
        // });
        break;
      }
    }
  }
}
