import { Col, Grid, Row } from "react-bootstrap";
import LogoLink from "./LogoLink";

const ErrorPage = () => (
  <Grid
    style={{
      background: "white",
      maxWidth: "730px",
      border: "1px solid #666",
      padding: "0 15px 14px 15px",
    }}
  >
    <Row
      style={{
        // marginBottom: "2em",
        paddingBottom: "1em",
        background: "#fff",
      }}
    >
      <Col xs={12}>
        <h2 className="pull-left">Oh No!</h2>
        <LogoLink />
      </Col>

      <Col xs={12} style={{ marginTop: "10px" }}>
        <p>
          An unexpected error has occurred. Please reload the page to try again.
        </p>
        <p>
          If this error persists, you can contact us at{" "}
          <a href="mailto:info@acp-usa.org">info@acp-usa.org</a> or 212-752-0700
          for assistance.
        </p>
      </Col>
    </Row>
  </Grid>
);

export default ErrorPage;
