import * as React from "react";
import { CompoundQuestion as CompoundQuestionModel } from "../lib/CompoundQuestion";
import {
  CompoundAnswer,
  Question as QuestionModel,
  SingleValueAnswer,
} from "../lib/Question";
import { AbstractQuestion, QuestionConstructor } from "./AbstractQuestion";
import { CheckboxesQuestion } from "./CheckboxesQuestion";
import { MultiLineTextQuestion } from "./MultiLineTextQuestion";
import { CompoundQuestionProps, QuestionProps } from "./QuestionProps";
import { RadiosQuestion } from "./RadiosQuestion";
import { RankedNSelectsQuestion } from "./RankedNSelectsQuestion";
import { SelectSingleQuestion } from "./SelectSingleQuestion";
import { SelectYearQuestion } from "./SelectYearQuestion";
import { SimpleTextQuestion } from "./SimpleTextQuestion";
import { ToDoQuestion } from "./ToDoQuestion";

export class CompoundQuestion extends AbstractQuestion<CompoundQuestionProps> {
  render(): any {
    const qm = this.props.question as CompoundQuestionModel;

    return <div>{qm.questions.map((q) => this.componentFor(q))}</div>;
  }

  setNewAnswers(name: string, answer: SingleValueAnswer): CompoundAnswer {
    const qm = this.props.question as CompoundQuestionModel;
    qm.setSubAnswer(name, answer);
    return qm.answer;
  }

  protected handleChange = (name: string, answer: SingleValueAnswer) => {
    this.props.question.setSubAnswer(name, answer);
    // const newAnswers = this.setNewAnswers(name, answer);
    this.onChange({ target: { value: this.props.question.answer } });
  };

  // protected handleBlur = (_name: string, _answer: SingleValueAnswer) => {
  //   // const newAnswers = this.setNewAnswers(name, answer);
  //   // this.onBlur({ target: { value: newAnswers } });
  // };

  protected handleBlur = () => {};

  componentFor(q: QuestionModel) {
    const map = new Map<string, QuestionConstructor>([
      ["text_simple", SimpleTextQuestion],
      ["text_multi", MultiLineTextQuestion],
      ["checkboxes", CheckboxesQuestion],
      ["radios", RadiosQuestion],
      ["select_single", SelectSingleQuestion],
      ["todo", ToDoQuestion],
      ["ranked_n_selects", RankedNSelectsQuestion],
      ["select_year", SelectYearQuestion],
      ["todo", ToDoQuestion],
    ]);

    if (!map.has(q.type)) {
      throw new Error(`Unknown question-type: '${q.type}'`);
    }

    const questionProps = {
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      question: q,
      dirty: this.props.dirty,
      key: q.name,
      applicationId: this.props.applicationId,
      isSubQuestion: true,
    };

    return React.createElement(
      map.get(q.type) as React.ComponentClass<QuestionProps, any>,
      questionProps,
    );
  }
}
