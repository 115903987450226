import { FormControl } from "react-bootstrap";
import { AbstractQuestion } from "./AbstractQuestion";
import { SingleValueQuestionProps } from "./QuestionProps";
import QuestionRow from "./QuestionRow";

export class SelectSingleQuestion extends AbstractQuestion<SingleValueQuestionProps> {
  render() {
    const q = this.props.question;

    return (
      <QuestionRow
        label={q.label}
        required={q.isRequired}
        errorText={q.errorText}
        visible={q.isVisible()}
        helpText={q.helpText}
        validationState={
          q.isValid() || !this.showValidationState ? null : "error"
        }
      >
        <FormControl
          componentClass="select"
          name={q.name}
          defaultValue={q.answer}
          onChange={this.onChange}
          onBlur={this.onBlur}
        >
          <option value="" />
          {Array.from(q.options.entries()).map(([val, lbl]) => (
            <option key={val} value={val}>
              {lbl}
            </option>
          ))}
        </FormControl>
        <FormControl.Feedback />
      </QuestionRow>
    );
  }
}
