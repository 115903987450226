import { QuestionVariety } from "./Definition";
import { SingleValueQuestion } from "./SingleValueQuestion";

export default class SelectYearQuestion extends SingleValueQuestion {
  get type(): QuestionVariety {
    return "select_year";
  }

  /**
   * Unsure if this is the correct place for this. But since most `...Question.tsx` prop types have
   * props.question as SingleValueQuestion instead of individual question types
   * Not sure if should create different props since only select_year will probably be the only
   * Question to implement start_year
   */
  get startYear(): number | undefined {
    return Number(this.def.start_year) || undefined;
  }
}
