import * as React from "react";
import { Alert, Button, Col, Form, Glyphicon, Row } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import Footer from "../components/Footer";
import Api from "../lib/api";
import { Pane as PaneModel } from "../lib/Pane";
import { ParticipantApplication } from "../lib/ParticipantApplication";
import { Answer } from "../lib/Question";
import { Survey as SurveyModel } from "../lib/Survey";
import HelpBox from "./HelpBox";
import LogoLink from "./LogoLink";
import { Pane } from "./Pane";
import * as Sentry from "@sentry/react";

interface Props extends RouteComponentProps {
  model: SurveyModel;
  activePaneNum: number;
  application: ParticipantApplication;
  loadThankYou: (appl?: ParticipantApplication) => void;
}

interface State {
  model: SurveyModel;
  activePaneDirty: boolean;
  errorText: string | null;
  errorDetail: string[] | null;
  processing: boolean;
}

const api = new Api();

class Survey extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      model: props.model,
      activePaneDirty: false,
      errorText: null,
      errorDetail: null,
      processing: false,
    };
  }

  onAnswer = (q: string, a: Answer) => {
    // set answer on model
    this.state.model.answer(q, a);
    // this.handlePatch(q, a);
    // const j = {} as any;
    // console.log(j.foo.baz);
    // throw new Error('bork bok');

    // update state
    this.setState({ model: this.state.model });
  };

  // handlePatch = debounce((q: string, a: Answer) => {
  //   api.patchAnswers(this.state.model.applicationId, [[q, a]]);
  // }, 200);

  savePane = async (pane: PaneModel) => {
    try {
      // it's not clear why we need to be explicit about CompoundAnswer here,
      // but TypeScript complains if we aren't.
      return await api.patchAnswers(
        this.props.application.applicationId,
        pane.questions.map((q) => [q.name, q.answer]),
      );
    } catch (e) {
      this.setState(() => {
        throw e;
      });
      return null;
    }
  };

  handleNext = async () => {
    if (this.activePane.isValid()) {
      this.setState({ activePaneDirty: false });

      await this.savePane(this.activePane);

      this.props.history.push(
        `/a/${this.state.model.applicationId}/${this.activePaneNum + 1}`,
      );
      window.scrollTo(0, 0);
    } else {
      // turn on validation
      this.setState({ activePaneDirty: true });
      this.activePane.scrollToError();
    }
  };

  handlePrev = async () => {
    if (this.activePaneNum > 1) {
      await this.savePane(this.activePane); // .then(v => console.log(v));
      this.props.history.push(
        `/a/${this.state.model.applicationId}/${this.activePaneNum - 1}`,
      );
      window.scrollTo(0, 0);
    }
  };

  handleSubmit = async () => {
    /**
     * Validate current pane
     */
    this.setState({ activePaneDirty: true });
    if (!this.activePane.isValid()) {
      this.activePane.scrollToError();
      return;
    }

    /**
     * Validate all other panes
     *
     * Debating on whether to include functionality that basically moves the
     * user back to an invalid pane just in case a user somehow tries to submit
     * with an invalid earlier pane which SHOULD be impossible I think.
     */

    for (const pane of this.state.model.panes) {
      if (!pane.isValid()) {
        return;
      }
    }

    const request: ParticipantApplication = {
      ...this.props.application,
      answers: this.state.model.answers,
    };
    this.setState({ processing: true });
    // this is a coarse fix for when the user submits while their preview
    // answer is still in-flight and propagating through DynamoDB. Should
    // cut down on transitory user-visible errors + dead-letter queue messages.
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const response = await api.submitApplication(request);

    if (response.status === 202 && response.data.success) {
      /* Handle success */
      this.setState({ processing: false });
      this.props.loadThankYou();
    } else {
      const nextState = {
        ...this.state,
        errorText: response.data.message,
        errorDetail: response.data.messages.join("<BR>"),
        processing: false,
      };
      this.setState(nextState);
    }
  };

  /**
   * Validate if activePaneNum is:
   * Invalid number, or if previous panes are invalid
   * Else set to 1
   */
  get activePaneNum() {
    if (
      isNaN(this.props.activePaneNum) ||
      this.props.activePaneNum === 1 ||
      this.props.activePaneNum > this.state.model.panes.length ||
      this.props.activePaneNum <= 0
    ) {
      return 1;
    }

    for (let i = 0; i < this.props.activePaneNum - 1; i++) {
      if (!this.state.model.panes[i].isValid()) {
        return 1;
      }
    }

    return this.props.activePaneNum;
  }

  get activePane() {
    return this.state.model.panes[this.activePaneNum - 1];
  }

  render() {
    const s = this.props.model;
    const paneCounter =
      s.panes.length > 1 ? `${this.activePaneNum}/${s.panes.length}` : "";
    Sentry.setUser({
      email: this.props.application.email,
      applicationId: this.props.application.applicationId,
    });
    return (
      <div>
        <Form horizontal={true}>
          <Row
            style={
              {
                // marginBottom: "2em",
                // paddingBottom: "1em",
                // background: "#fff"
              }
            }
          >
            <Col xs={8}>
              <h2 className="pull-left">{s.title}</h2>
            </Col>
            <Col xs={4}>
              <LogoLink />
            </Col>
            {/*<Col xs={12}>*/}
            {/*<h5>{s.welcomeText}</h5>*/}
            {/*</Col>*/}
          </Row>
          <Pane
            model={this.activePane}
            onAnswer={this.onAnswer}
            isDirty={this.state.activePaneDirty}
            paneCounter={paneCounter}
            applicationId={this.props.model.applicationId}
          />
          <Row hidden={this.state.errorText === null}>
            <Col xs={12}>
              <Alert bsStyle={"danger"}>
                {this.state.errorText}
                <small style={{ display: "block", marginTop: "1em" }}>
                  <p>Please try again.</p>
                  <p>
                    If this error persists, please contact us at (212) 752-0700
                    or <a href="mailto:info@acp-usa.org">info@acp-usa.org</a>{" "}
                    for assistance.
                  </p>
                </small>
              </Alert>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col xs={12}>
            {this.activePaneNum > 1 && (
              <Button onClick={this.handlePrev}>
                <i className="glyphicon glyphicon-arrow-left" /> Back
              </Button>
            )}

            {this.activePaneNum < this.state.model.panes.length && (
              <Button
                onClick={this.handleNext}
                className="pull-right btn-primary"
              >
                Save &amp; Continue
              </Button>
            )}

            {this.activePaneNum === this.state.model.panes.length && (
              <Button
                onClick={this.handleSubmit}
                className="pull-right btn-success"
                disabled={this.state.processing}
              >
                <Glyphicon
                  glyph={this.state.processing ? "refresh" : ""}
                  className={this.state.processing ? "spin" : undefined}
                  style={{ marginRight: this.state.processing ? "10px" : "0" }}
                />
                Submit
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div style={{ textAlign: "center", margin: "10px 0" }}>
              <HelpBox />
              <Footer />
            </div>
          </Col>
        </Row>
        {/*<button onClick={() => { this.setState(() => {throw new Error("No no no no no, nope!") }) } }>Detonate</button>*/}
        {/*<pre>{JSON.stringify(this.props.application.email)}</pre>*/}
      </div>
    );
  }
}

const SurveyWithRouter = withRouter(Survey);
export default SurveyWithRouter;
