import { QuestionDefinition, QuestionVariety } from "./Definition";
import { validatorFunc, visibilityFunc } from "./Question";
import { SingleValueQuestion } from "./SingleValueQuestion";

export default class ToDoQuestion extends SingleValueQuestion {
  get type(): QuestionVariety {
    return "todo";
  }

  // to-do questions are always valid
  constructor(
    def: QuestionDefinition,
    isVisible: visibilityFunc | undefined,
    validator: validatorFunc | undefined,
  ) {
    // just override the validator and call super.
    validator = () => ({ isValid: true, errorText: "" });
    super(def, isVisible, validator);
    this.answer = "@todo-question";
  }
}
