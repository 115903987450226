import * as React from "react";
import { Pane as PaneModel } from "../lib/Pane";
import { Answer, Question as QuestionModel } from "../lib/Question";
import {
  CompoundQuestionConstructor,
  QuestionConstructor,
} from "./AbstractQuestion";
import { CheckboxesQuestion } from "./CheckboxesQuestion";
import { CompoundQuestion } from "./CompoundQuestion";
import { MultiLineTextQuestion } from "./MultiLineTextQuestion";
import { PhotoUploadQuestion } from "./PhotoUploadQuestion";
import { QuestionProps } from "./QuestionProps";
import { RadiosQuestion } from "./RadiosQuestion";
import { RankedNSelectsQuestion } from "./RankedNSelectsQuestion";
import { SelectSingleQuestion } from "./SelectSingleQuestion";
import { SelectYearQuestion } from "./SelectYearQuestion";
import { SimpleTextQuestion } from "./SimpleTextQuestion";
import { ToDoQuestion } from "./ToDoQuestion";

interface Props {
  model: PaneModel;
  onAnswer: (questionName: string, answer: Answer) => void;
  isDirty: boolean;
  paneCounter: string;
  applicationId: string;
}

export class Pane extends React.Component<Props> {
  static defaultProps: {
    isDirty: false;
  };

  render() {
    const p = this.props.model;
    return (
      <div className="pane">
        <h3>
          {this.props.paneCounter} {p.title}
        </h3>
        {p.questions.map((q, i) => {
          return (
            <div key={q.name} ref={(ref) => (p.questionRefs[i] = ref)}>
              {this.componentFor(q)}
            </div>
          );
        })}
      </div>
    );
  }

  componentFor(q: QuestionModel) {
    const map = new Map<
      string,
      QuestionConstructor | CompoundQuestionConstructor
    >([
      ["text_simple", SimpleTextQuestion],
      // ['text_hidden', HiddenQuestion],
      ["text_multi", MultiLineTextQuestion],
      ["checkboxes", CheckboxesQuestion],
      ["radios", RadiosQuestion],
      ["select_single", SelectSingleQuestion],
      ["todo", ToDoQuestion],
      ["compound", CompoundQuestion],
      ["ranked_n_selects", RankedNSelectsQuestion],
      ["photo_upload", PhotoUploadQuestion],
      ["select_year", SelectYearQuestion],
      ["todo", ToDoQuestion],
    ]);

    if (!map.has(q.type)) {
      throw new Error(`Unknown question-type: '${q.type}'`);
    }

    const questionProps = {
      onChange: this.props.onAnswer,
      question: q,
      dirty: this.props.isDirty,
      key: q.name,
      applicationId: this.props.applicationId,
      isSubQuestion: false,
    };
    return React.createElement(
      map.get(q.type) as React.ComponentClass<QuestionProps, any>,
      questionProps,
    );
  }
}
