import logo from "../acp-logo-2019.color.png";

const LogoLink = () => (
  <a
    href="https://www.acp-usa.org"
    target="_orgsite"
    className="pull-right"
    style={{ padding: "0", marginTop: "12px" }}
  >
    <img
      src={logo}
      alt="ACP Mentoring Program"
      style={{ height: "35px", width: "auto" }}
    />
  </a>
);

export default LogoLink;
