import axios from "axios";

export default axios.create({
  baseURL: import.meta.env.VITE_API_BASEURL,

  /*
    Note: we might consider doing this in the future.
    The Default is `(status) => status >= 200 && status < 300`, which means that
    things like a 400 response on bad input will reject/throw.  It's not
    entirely clear to me if we should consider validation failures "exceptional"
    conditions.  I'm leaning towards considering them expected, and updating
    code to handle them in the success path.  That would leave catch() to handle
    truly unexpected situations.

    For now, I think we'll try passing a custom validateStatus in a few specific
    places and see how it goes.
   */
  // ,validateStatus: (status: number) => status < 500
});
