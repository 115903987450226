const determineBase = () => {
  // prefer explicit value from environment
  if (import.meta.env.VITE_MOTHERSHIP_BASEURL) {
    return import.meta.env.VITE_MOTHERSHIP_BASEURL;
  }

  // otherwise, if this is prod, use hardcoded prod value
  if (import.meta.env.VITE_ACP_ENV === "prod") {
    return "https://ops.acp-usa.org";
  }

  // else default to the QA dashboard
  return "https://ops.qa.acp-usa.org";
};

export default {
  baseUrl: determineBase(),
};
