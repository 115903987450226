import axios from "axios";
import * as React from "react";
import { Alert, Glyphicon } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import Api from "../lib/api";
import { validateSurveyDefinition } from "../lib/Definition";
import { ParticipantApplication } from "../lib/ParticipantApplication";
import { Survey as SurveyModel } from "../lib/Survey";
import Survey from "./Survey";
import acpLogoImg from "../acp-logo-2019.color.png";

interface Props {
  uuid: string;
  pane?: string;
}

interface DangerouslySetInnerHTML {
  __html: string;
}

interface State {
  loading: boolean;
  error: string | null;
  survey: SurveyModel | null;
  application: ParticipantApplication | null;
  thankYouHtml?: DangerouslySetInnerHTML;
}

const api = new Api();
const defaultState = {
  loading: true,
  error: null,
  survey: null,
  application: null,
};

class SurveyLoader extends React.Component<RouteComponentProps<Props>, State> {
  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = defaultState;
  }

  async componentDidMount() {
    try {
      // load application data from backend.
      const appl = await api.getApplicationById(this.props.match.params.uuid);

      if (!appl) {
        await this.notFound();
        return;
      }

      if (appl.submittedAt) {
        await this.loadThankYou(appl);
        return;
      }

      // Load and validate survey definition
      if (!this.surveysMap.has(appl.surveyDefinitionUrl)) {
        throw new Error(
          `Invalid survey definition: ${appl.surveyDefinitionUrl}`,
        );
      }
      const actualSurveyDefinitionUrl =
        this.surveysMap.get(appl.surveyDefinitionUrl) +
        "?t=" +
        new Date().getTime();

      const surveyDef = validateSurveyDefinition(
        (await axios.get(actualSurveyDefinitionUrl!)).data,
      );

      // Initialize survey model
      const survey = new SurveyModel(
        surveyDef,
        appl.applicationId,
        appl.answers,
      );
      await survey.init();

      const cookies = new Cookies();
      cookies.remove("referred-by");

      this.setState({
        application: appl,
        survey,
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState(() => {
        throw e;
      });
    }
  }

  private readonly base = "/survey-definitions/";

  private readonly surveysMap = new Map<string, string>([
    ["mentor", `${this.base}mentor-corp.json`],
    ["mentor-corp", `${this.base}mentor-corp.json`],
    ["mentor-ctzn", `${this.base}mentor-ctzn.json`],
    ["mentor-spouse", `${this.base}mentor-spouse.json`],
    ["protege-fumch", `${this.base}protege-fumch.json`],
    ["protege-sowf", `${this.base}protege-sowf.json`],
    ["protege-spouse", `${this.base}protege-spouse.json`],
    ["protege", `${this.base}protege.json`],
  ]);

  private readonly thankyouPageMap = new Map<string, string>([
    ["mentor-corp", `${this.base}mentor.html`],
    ["mentor-ctzn", `${this.base}mentor-ctzn.html`],
    ["mentor-spouse", `${this.base}mentor.html`],
    ["protege-fumch", `${this.base}protege.html`],
    ["protege-sowf", `${this.base}protege.html`],
    ["protege-spouse", `${this.base}protege.html`],
    ["protege", `${this.base}protege.html`],
  ]);

  render() {
    if (this.state.loading) return this.loadingContent();
    if (this.state.thankYouHtml)
      return <div dangerouslySetInnerHTML={this.state.thankYouHtml} />;
    if (this.state.error) {
      return (
        <Alert bsStyle="warning">
          Error: <pre>{this.state.error}</pre>
        </Alert>
      );
    }
    if (this.state.survey) {
      return (
        <Survey
          application={this.state.application!}
          model={this.state.survey}
          activePaneNum={Number(this.props.match.params.pane || 1)}
          loadThankYou={this.loadThankYou}
        />
      );
    }
    return "error";
  }

  loadThankYou = async (
    appl: ParticipantApplication = this.state.application!,
  ) => {
    const actualPageUrl = this.thankyouPageMap.has(appl?.surveyDefinitionUrl)
      ? this.thankyouPageMap.get(appl.surveyDefinitionUrl)
      : this.thankyouPageMap.get("protege");
    const html = (await axios.get(actualPageUrl!)).data;
    console.info(`Loaded tyHTML from: ${actualPageUrl}`, { html });
    this.setState({
      thankYouHtml: { __html: html },
      loading: false,
    });
  };

  notFound = async () => {
    this.setState({
      thankYouHtml: {
        __html: (await axios.get(`${this.base}application-not-found.html`))
          .data,
      },
      loading: false,
    });
  };

  loadingContent() {
    return (
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          width: "100%",
          background: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={acpLogoImg} alt="Loading" style={{ height: "80px" }} />
          <h3 style={{ color: "#182654" }}>
            <Glyphicon glyph="refresh" className="spin" />
          </h3>
        </div>
      </div>
    );
  }
}

const SurveyLoaderWithRouter = withRouter(SurveyLoader);
export default SurveyLoaderWithRouter;
