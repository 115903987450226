import { Answer, Question } from "./Question";

export abstract class SingleValueQuestion extends Question {
  protected answerValue: Answer = "";

  set answer(a: string) {
    this.answerValue = a === null ? "" : a;
  }

  get answer(): string {
    return this.answerValue as string;
  }

  get isEmpty() {
    return (this.answerValue as string).trim() === "";
  }
}
