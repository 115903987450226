import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App.tsx";
import "./index.css";
import ErrorPage from "./components/ErrorPage.tsx";

if (import.meta.env.VITE_ACP_ENV !== "dev") {
  Sentry.init({
    dsn: "https://4de1b3cce57bc9541dec891c4e9dabf3@o31360.ingest.us.sentry.io/4507059690930176",
    integrations: [],
    environment: import.meta.env.VITE_ACP_ENV,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
