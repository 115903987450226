import { MultiValueAnswer, Question } from "./Question";
export abstract class MultiValueQuestion extends Question {
  protected answerValue: MultiValueAnswer = [];

  /**
   * Ensures answers are in same order as options.  Can be overridden in subclasses
   * to provide other sorting strategies (as in RankedNSelectsQuestion).
   */
  protected sortAnswers(answers: MultiValueAnswer) {
    return Array.from(this.options.keys()).filter((s) => answers.includes(s));
  }

  /**
   * Filter answers,
   * checks if answer is included in options
   * Can be overridden
   */
  protected filterAnswers(answers: MultiValueAnswer): MultiValueAnswer {
    return answers.filter((answer) => answer.length > 0);
  }

  get maxAnswers(): number | undefined {
    return this.def.maxAnswers;
  }

  // get questions() {
  //   return this.def.questions;
  // }

  get answer(): MultiValueAnswer {
    return this.answerValue;
  }

  set answer(a: MultiValueAnswer) {
    let filteredAnswers = this.filterAnswers(a);

    // if there are options
    if (this.options.size > 0) {
      const opts = Array.from(this.options.keys());

      filteredAnswers.forEach((s) => {
        if (opts.indexOf(s as string) === -1)
          console.warn(
            `Answer '${s}' is not a valid option for question '${this.name}'`,
          );
        // throw new Error(
        //   `Answer '${s}' is not a valid option for question '${this.name}'`
        // );
      });

      filteredAnswers = this.sortAnswers(filteredAnswers);
    }

    this.answerValue = filteredAnswers;
  }

  get isEmpty() {
    return this.answerValue.length === 0;
  }
}
