import * as React from "react";
import {
  Col,
  Collapse,
  ControlLabel,
  FormGroup,
  HelpBlock,
} from "react-bootstrap";

const RequiredMarker = () => <span className="text-danger">&nbsp;*</span>;

interface Props {
  label: string;
  required: boolean;
  helpText?: string;
  errorText?: string;
  validationState: "error" | null;
  visible: boolean;
  children: any; // why do we need this now?
}

class QuestionRow extends React.PureComponent<Props> {
  render() {
    const { label, required, helpText, errorText, visible, validationState } =
      this.props;
    // console.log(label, helpText, validationState, errorText);
    return (
      <Collapse in={visible}>
        <FormGroup validationState={validationState}>
          <Col xs={12} sm={4}>
            <ControlLabel>
              {label}
              {required && <RequiredMarker />}
            </ControlLabel>
          </Col>
          <Col xs={12} sm={8}>
            {this.props.children}
            {helpText && <HelpBlock>{helpText}</HelpBlock>}
            {validationState && <HelpBlock>{errorText}</HelpBlock>}
          </Col>
        </FormGroup>
      </Collapse>
    );
  }
}

export default QuestionRow;
