import { Checkbox, FormControl } from "react-bootstrap";
import { SingleValueAnswer } from "../lib/Question";
import { AbstractQuestion } from "./AbstractQuestion";
import { MultiValueQuestionProps } from "./QuestionProps";
import QuestionRow from "./QuestionRow";

interface CheckboxesState {
  values: Set<SingleValueAnswer>;
}

export class CheckboxesQuestion extends AbstractQuestion<
  MultiValueQuestionProps,
  CheckboxesState
> {
  constructor(props: MultiValueQuestionProps) {
    super(props);
    this.state = {
      values: new Set(props.question.answer),
    };
  }

  render() {
    const q = this.props.question;

    return (
      <QuestionRow
        label={q.label}
        required={q.isRequired}
        errorText={q.errorText}
        visible={q.isVisible()}
        helpText={q.helpText}
        validationState={
          q.isValid() || !this.showValidationState ? null : "error"
        }
      >
        {Array.from(q.options.entries()).map(([val, lbl]) => (
          <Checkbox
            key={val}
            name={q.name}
            value={val}
            onChange={this.onToggle}
            checked={q.answer.includes(val)}
          >
            {lbl}
          </Checkbox>
        ))}

        <FormControl.Feedback />
      </QuestionRow>
    );
  }

  onToggle = (e: any) => {
    // generate new set based on old set.
    const newSet = new Set(this.state.values);

    // update set
    if (e.target.checked) newSet.add(e.target.value);
    else newSet.delete(e.target.value);

    // commit state
    this.setState({ values: newSet });

    // propagate change up.
    this.onChange({ target: { value: Array.from(newSet) } });
  };
}
