import * as React from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Glyphicon,
  HelpBlock,
  Row,
} from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import Footer from "../components/Footer";
import HelpBox from "../components/HelpBox";
import LogoLink from "../components/LogoLink";
import Api from "../lib/api";
import Cookies from "universal-cookie";
import { errToStr } from "../lib/utils.ts";

const api = new Api();

const defaultState = {
  email: "",
  agree: "disagree",
  errorEmail: "",
  errorAgree: "",
  loading: false,
  errorText: null,
  errorDetail: null,
};
interface StartNoCodeProps {
  surveyDefinitionUrl:
    | "protege"
    | "protege-spouse"
    | "protege-fumch"
    | "protege-sowf"
    | "mentor-spouse";
}

interface State {
  email: string;
  agree: string;
  errorEmail: string;
  errorAgree: string;
  loading: boolean;
  errorText: string | null;
  errorDetail: string | null;
}

// @todo - build a component for handling the create-send-email behavior.
export class StartNoCode extends React.Component<
  RouteComponentProps<StartNoCodeProps>,
  State
> {
  state = defaultState;

  handleChange = (evt: any) => {
    const newState = {
      email: evt.currentTarget.value,
      errorEmail: "",
    };
    this.setState(newState);
  };

  handleToggle = (evt: any) => {
    const newState = {
      agree: evt.currentTarget.checked ? "agree" : "disagree",
      errorAgree: "",
    };
    this.setState(newState);
  };

  handleSubmit = async (evt: any) => {
    evt.preventDefault();
    try {
      const agreeValid = this.validateAgree();
      const emailValid = this.validateEmail();

      if (agreeValid && emailValid) {
        const cookies = new Cookies();
        const newApp = {
          email: this.state.email.trim(),
          agree: this.state.agree,
          surveyDefinitionUrl: this.props.match.params.surveyDefinitionUrl,
          referredBy: cookies.get("referred-by"),
        };

        this.setState({ loading: true });
        const response = await api.createApplicationSendEmail(newApp);
        this.setState({ loading: false });
        if (response.status === 201 && response.data.success) {
          this.props.history.push(`/a/${response.data.message}`);
        } else {
          const nextState = {
            ...this.state,
            errorText: response.data.message,
            errorDetail: response.data.messages.join("\n"),
            loading: false,
          };
          this.setState(nextState);
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        errorText: "Something unexpected went wrong.  Please try again later.",
        errorDetail: errToStr(e),
        loading: false,
      });
    }
  };

  validateAgree = (): boolean => {
    if (this.state.agree !== "agree") {
      this.setState({
        errorAgree: "You must check the box to agree",
      });
      return false;
    } else {
      this.setState({
        errorAgree: "",
      });
      return true;
    }
  };

  // this, like all email regular expressions is technically horribly wrong
  // and bad.  It ensures exactly one @ and at least one dot (.), in that order,
  // with some other non-space characters surrounding them, and at least two
  // chars after the dot.  In a less imperfect world, it would ensure there
  // are no consecutive dots (..) too.
  private emailRegex = /^[^ @]+@[^ @]+\.[^ @][^ @]+$/;

  validateEmail = () => {
    if (this.state.email.length === 0) {
      this.setState({
        errorEmail: "You must enter an email address.",
      });

      return false;
    } else if (!this.emailRegex.test(this.state.email.trim())) {
      this.setState({
        errorEmail: "Please enter a valid email address",
      });

      return false;
    } else {
      this.setState({
        errorEmail: "",
      });

      return true;
    }
  };

  renderSpouseText() {
    return (
      <span>
        {" "}
        Questions about your spouse’s service are strictly for demographic
        reporting - we look forward to learning about <em>your</em> career
        ambitions.
      </span>
    );
  }

  render() {
    const appType = this.props.match.params.surveyDefinitionUrl;
    const userTypes = {
      protege: "Veteran Protégé",
      "protege-fumch": "Protégé",
      "protege-sowf": "Veteran Protégé",
      "protege-spouse": "Spouse Protégé",
      "mentor-spouse": "Spouse Mentor",
    };
    const partnerType = appType === "mentor-spouse" ? "Protégé" : "Mentor";
    return (
      <div>
        <Row
          style={{
            paddingBottom: "1em",
            background: "#fff",
          }}
        >
          <Col xs={12}>
            <h2 className="pull-left">Welcome</h2>
            <LogoLink />
          </Col>
          <Col xs={12}>
            <p>
              Thank you for applying to be an ACP {userTypes[appType]}. Please
              be as thorough as possible in your application so that we can find
              the best {partnerType} for you to work with.
              {appType === "protege-spouse" ? this.renderSpouseText() : ``}
            </p>
            <p>
              Our application takes about 15 minutes to complete. To begin your
              application, please enter your email address:
            </p>
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit}>
          <Row hidden={this.state.errorText === null}>
            <Col xs={12}>
              <Alert bsStyle={"warning"}>
                {this.state.errorText}
                <small style={{ display: "block", marginTop: "1em" }}>
                  {this.state.errorDetail}
                </small>
              </Alert>
            </Col>
          </Row>

          <FormGroup
            validationState={
              this.state.errorEmail.length > 0 ? "error" : undefined
            }
            controlId="email-addr"
          >
            <Row>
              <Col style={{ textAlign: "right" }} xs={12} sm={3}>
                <ControlLabel>Email Address</ControlLabel>
              </Col>
              <Col xs={12} sm={9}>
                <FormControl
                  type="email"
                  name="email"
                  value={this.state.email.trim()}
                  onChange={this.handleChange}
                  autoComplete="email"
                />
                <HelpBlock>{this.state.errorEmail}</HelpBlock>
              </Col>
            </Row>
          </FormGroup>

          <Row>
            <Col xs={12} mdOffset={3} md={9}>
              <Checkbox
                name="agree"
                onChange={this.handleToggle}
                checked={this.state.agree === "agree"}
                validationState={
                  this.state.errorAgree.length > 0 ? "error" : undefined
                }
              >
                I accept that ACP staff will use some or all of the information
                provided in this application as a means of introduction to my{" "}
                {partnerType}.<HelpBlock>{this.state.errorAgree}</HelpBlock>
              </Checkbox>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Button
                disabled={this.state.loading}
                className="pull-right"
                type="submit"
                bsStyle="primary"
              >
                <Glyphicon
                  glyph={this.state.loading ? "refresh" : ""}
                  className={this.state.loading ? "spin" : undefined}
                  style={{ marginRight: this.state.loading ? "10px" : "0" }}
                />
                Continue
              </Button>
            </Col>
          </Row>
        </Form>

        {/*<button onClick={() => methodDoesNotExist()}>Break the world</button>;*/}
        <div style={{ textAlign: "center", margin: "10px 0" }}>
          <HelpBox />
          <Footer />
        </div>
      </div>
    );
  }
}
