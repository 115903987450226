import axios from "axios";
import mothership from "../lib/mothership";
import {
  QuestionDefinition,
  QuestionVariety,
  StringOptions,
} from "./Definition";

interface ValidationResult {
  isValid: boolean;
  errorText: string;
}

// some handy function types, to be explicit:
export type validatorFunc = (val: Answer) => ValidationResult;
export type visibilityFunc = () => boolean;

// a validatorFunc that always passes.
const alwaysValid = () => ({ isValid: true, errorText: "" });

// Move this to factory? Move factory stuff here?
export type QuestionConstructor = new (
  def: QuestionDefinition,
  isVisible?: visibilityFunc,
  validator?: validatorFunc,
) => Question;

// interface that is implemented by both `Survey` and `Pane`
export interface QuestionContext {
  hasQuestionNamed(name: string): boolean;
  getQuestion(name: string): Question;
}

export type SingleValueAnswer = string;
export type MultiValueAnswer = SingleValueAnswer[];
export type SimpleAnswer = SingleValueAnswer | MultiValueAnswer;
export type Answer = SimpleAnswer | CompoundAnswer | null;
// export interface CompoundAnswer extends Map<string, Answer> {}
export interface CompoundAnswer extends Array<[string, Answer]> {}

export const isCompoundAnswer = (a: Answer): a is CompoundAnswer => {
  return typeof a === "object" && typeof a!.entries === "function";
};

export abstract class Question {
  // defined as a getter because properties defined in subclasses won't be
  // initialized when the assertion is made in the constructor below.
  abstract get type(): QuestionVariety;

  protected answerValue: Answer = null;
  isVisible: visibilityFunc;
  validator: validatorFunc;
  private currentErrorText: string = ""; // current validation message
  private opts: Map<string, string> = new Map<string, string>();

  protected requiredErrorText: string = "This question requires an answer.";

  // Your IDE/editor might suggest making this constructor protected.  Don't believe it.
  constructor(
    protected readonly def: QuestionDefinition,
    isVisible?: visibilityFunc,
    validator?: validatorFunc,
  ) {
    this.assertQuestionType(def);
    this.isVisible = isVisible || (() => true);
    this.validator = validator || alwaysValid;
    // @todo - this sanity check can probably be removed because definition types are better now.
    if (def.options && def.optionsUrl) {
      throw new Error(
        `Properties 'options' and 'optionsUrl' are mutually exclusive in definition of question: '${def.name}'`,
      );
    }
    if (def.options) this.buildOptionsMap(def.options);
  }

  async init() {
    if (this.def.optionsUrl)
      await this.buildOptionsMapFromUrl(this.def.optionsUrl);
  }

  private buildOptionsMap(o: StringOptions) {
    this.opts = new Map(Object.entries(o));
  }

  private async buildOptionsMapFromUrl(u: string) {
    // @todo - validate this URL somewhere?  Maybe moving definitions to json-schema wil give us better guarantees?

    // accept both fully-qualified URLs or absolute paths
    if (u.toLowerCase().substring(0, 4) !== "http") {
      u = mothership.baseUrl + u;
    }
    const res = await axios.get(u);
    this.opts = new Map(res.data);
  }

  private assertQuestionType(def: QuestionDefinition) {
    if (def.type !== this.type) {
      throw new Error(
        `Question-type mismatch.  Expected: ${this.type} Got: ${def.type}`,
      );
    }
  }

  /* Validates the user-supplied answer */
  isAnswerValid() {
    const { isValid, errorText } = this.validator(this.answerValue);
    this.currentErrorText = errorText;
    return isValid;
  }

  /* Validates user-supplied answer and 'required' status. */
  isValid() {
    // not valid if required and empty
    if (this.isRequired && this.isEmpty && this.isVisible()) {
      this.currentErrorText = this.requiredErrorText;
      return false;
    }

    // not valid if non-empty and fails validation
    if (!this.isEmpty && !this.isAnswerValid() && this.isVisible()) {
      return false;
    }

    // else valid
    this.currentErrorText = "";
    return true;
  }

  abstract get isEmpty(): boolean;

  // getters for properties of private readonly copy of definition
  get label(): string {
    return this.def.label;
  }

  get name(): string {
    return this.def.name;
  }

  get isRequired(): boolean {
    return this.def.required || false;
  }

  get target(): string {
    return this.def.target;
  }

  get position(): number {
    return this.def.position || 0;
  }

  get helpText(): string {
    return this.def.helpText || "";
  }

  get options(): Map<string, string> {
    return this.opts;
  }

  get placeholder(): string {
    return this.def.placeholder || "";
  }

  get autoComplete(): string {
    return this.def.autoComplete || "";
  }

  get errorText(): string {
    // Must call isValid here to update currentErrorText before returning it!
    this.isValid();
    return this.currentErrorText;
  }

  abstract set answer(a: Answer);

  abstract get answer(): Answer;
}

// returns a closure over `expr` and `msg` that validates a string
export const regexValidator = (expr: RegExp, msg: string) => (val: Answer) => ({
  isValid: expr.test(val as string),
  errorText: msg,
});
