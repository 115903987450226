/**
 * Used to load a component async
 * https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
 */
import { Component, ComponentType } from "react";

interface State {
  component: ComponentType | null;
}

export default function asyncComponent(importComponent: () => Promise<any>) {
  class AsyncComponent extends Component<object, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({ component });
    }

    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
