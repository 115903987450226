// import Cookies from "universal-cookie";

type Affiliate = {
  referrer: string;
  applicationPath: string;
  callback?: () => void;
};

const affiliates = new Map<string, Affiliate>();

affiliates.set("/virginia-spouses", {
  referrer: "Virginia DVS",
  applicationPath: "protege-spouse",
});

affiliates.set("/virginia", {
  referrer: "Virginia DVS",
  applicationPath: "protege",
});

affiliates.set("/vre", {
  referrer: "VR&E",
  applicationPath: "protege",
});

affiliates.set("/vrespouse", {
  referrer: "VR&E",
  applicationPath: "protege-spouse",
});

affiliates.set("/linkedin", {
  referrer: "LinkedIn",
  applicationPath: "mentor/ACPUSA",
  // callback: (a) => {
  //   const cookies = new Cookies();
  //   cookies.set("referrer", "linkedin", { path: "/", maxAge: 60 * 60 * 24 * 14 });
  // }
});

affiliates.set("/bsf", {
  referrer: "BSF",
  applicationPath: "protege-spouse",
});

affiliates.set("/therosienetwork", {
  referrer: "The Rosie Network",
  applicationPath: "protege",
});

affiliates.set("/therosienetwork-spouses", {
  referrer: "The Rosie Network",
  applicationPath: "protege-spouse",
});

affiliates.set("/warrior-rising", {
  referrer: "Warrior Rising",
  applicationPath: "protege",
});

affiliates.set("/warrior-rising-spouses", {
  referrer: "Warrior Rising",
  applicationPath: "protege-spouse",
});

affiliates.set("/ibx", {
  referrer: "IBX",
  applicationPath: "protege",
});

export default affiliates;
