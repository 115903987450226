import { SurveyDefinition } from "./Definition";
import { Pane } from "./Pane";
import { Answer, Question, QuestionContext } from "./Question";

// answers passed in from database
type Answers = Array<[string, Answer]>;

export class Survey implements QuestionContext {
  title: string;
  type: string | null = null;
  welcomeText: string;
  agreeText: string;
  panes: Pane[];

  constructor(
    def: SurveyDefinition,
    readonly applicationId: string,
    answers: Answers,
  ) {
    this.title = def.title;
    this.welcomeText = def.welcomeText;
    this.agreeText = def.agreeText;
    this.panes = [];
    def.panes.forEach((pd) => {
      this.panes.push(new Pane(pd.questions, pd.title));
    });

    this.loadAnswers(answers);
  }

  loadAnswers(answers: Answers) {
    answers.forEach(([q, a]) => {
      try {
        this.answer(q, a);
      } catch (e) {
        console.error({ e, q, a });
      }
    });
  }

  /**
   * Initialize all questions.  Async because questions' init()
   * may make network calls to load options, etc.
   */
  async init() {
    await Promise.all(
      this.questions.map(async (q) => {
        await q.init();
      }),
    );
  }

  get questions() {
    return this.panes
      .map((p) => p.questions)
      .reduce((prev, curr) => prev.concat(curr));
  }

  get answers(): Array<[string, Answer]> {
    return this.questions.map((q): [string, Answer] => [
      q.name as string,
      q.answer as Answer,
    ]);
  }

  hasQuestionNamed(name: string) {
    return !!this.questions.find((q) => q.name === name);
  }

  // returns named question or throws if not found.
  getQuestion(name: string): Question {
    const question = this.questions.find((q) => q.name === name);
    if (!question) {
      throw new Error(`No question named '${name}' in survey.`);
    }
    return question;
  }

  serialize(): string {
    return JSON.stringify(
      this.questions.map((q) => {
        return {
          name: q.name,
          target: q.target,
          answer: q.answer,
          visible: q.isVisible(),
        };
      }),
      null,
      2,
    );
  }

  answer(qname: string, answer: Answer) {
    try {
      this.getQuestion(qname).answer = answer ? answer : "";
    } catch (e) {
      // do nothing because it might be an obsolete question
      console.warn(e);
    }
  }
}
