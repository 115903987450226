import { FormControl } from "react-bootstrap";
import SelectYearQuestionType from "../lib/SelectYearQuestion";
import { AbstractQuestion } from "./AbstractQuestion";
import { SingleValueQuestionProps } from "./QuestionProps";
import QuestionRow from "./QuestionRow";

export class SelectYearQuestion extends AbstractQuestion<SingleValueQuestionProps> {
  currentYear = new Date().getFullYear();
  generateOptions = (startYear: number = 1975) => {
    const opts = [];
    for (let i = this.currentYear; i >= startYear; i--) {
      opts.push(
        <option value={i} key={i}>
          {i}
        </option>,
      );
    }

    return opts;
  };
  render() {
    const q = this.props.question as SelectYearQuestionType;

    return (
      <QuestionRow
        label={q.label}
        required={q.isRequired}
        errorText={q.errorText}
        visible={q.isVisible()}
        helpText={q.helpText}
        validationState={
          q.isValid() || !this.showValidationState ? null : "error"
        }
      >
        <FormControl
          componentClass="select"
          name={q.name}
          defaultValue={q.answer}
          onChange={this.onChange}
          onBlur={this.onBlur}
        >
          <option value="" />
          {this.generateOptions(q.startYear!)}
        </FormControl>
        <FormControl.Feedback />
      </QuestionRow>
    );
  }
}
