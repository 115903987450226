import { Alert } from "react-bootstrap";

const HelpBox = () => (
  <Alert bsStyle="info">
    <strong>Questions?</strong>
    <div>
      Contact us at <a href="mailto:info@acp-usa.org"> info@acp-usa.org </a>
      <span style={{ whiteSpace: "nowrap" }}>or (212) 752-0700.</span>
    </div>
  </Alert>
);

export default HelpBox;
